import styled from "styled-components";
import { mainColor,secondColor,darkColor } from "../../../color";
export const ExperienceDiv = styled.div`
  @keyframes slideInRight {
        0% {
          transform: translateX(100%);
        }
        100% {
          transform: translateX(0);
        }
      }
    position: relative;
    top: 35%;
    left:45%;
    width: 50rem;
    height:30rem;
    border-radius: 2%;
    border: solid 2px ${darkColor};
    background-color:black;
    animation-name: slideInRight;
    animation-duration: 2s;
    transition: font-size 0.5s,height 0.3s,width 0.3s;
    &:hover{
    border: solid 5px ${mainColor};
    font-size: 1.4rem;
    height:34rem;
    p{
      transition: font-size 0.5s;
      font-size:1.5rem;
      color:${secondColor};
      
    }
    em{
      transition: font-size 0.5s;
      font-size:1.5rem;
      color:${mainColor};
    }
    }
  
    @media only screen and (max-width: 1324px) {
    top: 40%;
    left:0;
    width: 35rem;
    margin: 0 auto;
    height: 25rem;

    &:hover{
    border: solid 5px ${mainColor};
    font-size: 1.4rem;
    height:27rem;
    p{
      transition: font-size 0.5s;
      font-size:1.1rem;
      color:${secondColor};
      
    }
    em{
      transition: font-size 0.5s;
      font-size:1.1rem;
      color:${mainColor};
    }
    }
    @media only screen and (max-width: 820px) {
    top: 40%;
    left:0;
    width: 22rem;
    margin: 0 auto;
    height: 20rem;

    &:hover{
    border: solid 5px ${mainColor};
    font-size: 1.3rem;
    height:23rem;
    width:23.5rem;
    p{
      transition: font-size 0.5s;
      font-size:0.9rem;
      color:${secondColor};
      
    }
    em{
      transition: font-size 0.5s;
      font-size:0.9rem;
      color:${mainColor};
    }
    }
  }
  }
`;
export const Title = styled.h1`
    position: absolute;
    top: 5%;
    left:5%;
    width: 50%;
    color: ${mainColor};
    @media only screen and (max-width: 1324px) {
        font-size: 1.6rem;
  }
  @media only screen and (max-width: 820px) {
    font-size: 1.2rem;
    
  }
`;
export const Explain = styled.p`

    position:relative;
    top: 20%;
    left: 5%;
    width: 90%;
    font-size:1.4rem;
    line-height: 1.8;
    color:${darkColor};
    @media only screen and (max-width: 1324px) {
        font-size: 1rem;
  }
  @media only screen and (max-width: 820px) {
    font-size: 0.8rem;
    
  }
`;
export const ImportantParts = styled.em`
  color:${secondColor};
  font-style: italic;
  margin:0;
  padding:0;
`;