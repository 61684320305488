import React, { useState,useEffect } from "react";
import { ContainerDiv, InputUserName, MenuDiv, Title, SubmitButton,LogOutButton, InputFriend } from "./menuStyle";
import { UserCard } from "./userCard";
import { auth,db } from "../../firebase";
import { onAuthStateChanged,signOut } from "firebase/auth";
import { addDoc, collection, query, where, getDocs,getDoc,doc,updateDoc } from 'firebase/firestore';




export const Menu = ()=>{
    const [authUser,setAuthUser] = useState(null);
    const [userName,setUserName] = useState("")
    const [new_user,setNewUser] = useState("")
    const [all_users,setAll] = useState([])
    const [friends,setFriends] = useState([])
    const [new_friend,setNewFriend] = useState([])





    useEffect(()=>{
        const listen = onAuthStateChanged(auth,(user)=>{
            if(user){
                setAuthUser(user)
                getFriendsByUsername(userName)
                
                
            }
            else{
                setAuthUser(null)
            }
        });

        return() =>{
            listen();
        }
    },[])
    const getUsernameFromEmail = async (email) => {
        try {
          const usersRef = collection(db, 'usernames');
          const q = query(usersRef, where('email', '==', email));
          const snapshot = await getDocs(q);
      
          if (!snapshot.empty) {
            const userData = snapshot.docs[0].data();
            setUserName(userData.username);
            
            
            // Do something with the username
            
          } else {
            // Email not found
            console.log('Email not found');
          }
        } catch (error) {
          console.error('Error fetching username from email:', error);
        }
      };

      const addUserToDatabase = async (username, email) => {
        try {
          const usersRef = collection(db, 'usernames');
      
          // Check if the username already exists
          const q = query(usersRef, where('username', '==', username));
          const querySnapshot = await getDocs(q);
          if (!querySnapshot.empty) {
            console.log('Username already exists');
            window.alert("This username has already taken.")
            return;
          }
      
          // Add user data to the database
          const userData = {
            username: username,
            email: email,
            friends: []
          };
          const docRef = await addDoc(usersRef, userData);
          console.log('User added with ID:', docRef.id);
          window.location.reload()
        } catch (error) {
          console.error('Error adding user to database:', error);
        }
      };

    useEffect(()=>{
        getDocs(collection(db, "usernames"))
           .then((querySnapshot)=>{               
               const newData = querySnapshot.docs
                   .map((doc) => ({...doc.data(), id:doc.id }));
                   setAll(newData);                
               
           })
   }, [])
   const getFriendsByUsername = async (username) => {
    try {
      const collectionRef = collection(db, 'usernames');
      const q = query(collectionRef, where('username', '==', username));
      const querySnapshot = await getDocs(q);
  
      if (!querySnapshot.empty) {
        const documentData = querySnapshot.docs[0].data();
        if(documentData.friends !== null){
            setFriends(documentData.friends);
            
        }
        
      } else {
        console.log('No document found for the username:', username);
      }
    } catch (error) {
      console.error('Error fetching document:', error);
    }
  };

  const addFriend = async (newFriend) => {
    try {
      const usernamesRef = collection(db, 'usernames');
      
      const usernamesQuery = query(usernamesRef, where('username', '==', newFriend));
      const usernamesSnapshot = await getDocs(usernamesQuery);
  
      if (!usernamesSnapshot.empty) {
        const usernamesQuery = query(usernamesRef, where('username', '==', userName));
        const usernamesSnapshot = await getDocs(usernamesQuery);
        const aliDocRef = doc(db, 'usernames', usernamesSnapshot.docs[0].id);
        const aliDocSnapshot = await getDoc(aliDocRef);
  
        if (aliDocSnapshot.exists()) {
          const aliData = aliDocSnapshot.data();
          if(aliData.friends){
                const updatedFriends = [...aliData.friends, newFriend];
                await updateDoc(aliDocRef, { friends: updatedFriends });
                console.log('Friend added successfully!');
                window.alert("Friend added successfully!");
                getFriendsByUsername(userName)

          }
          else{
                const updatedFriends = [newFriend];
                await updateDoc(aliDocRef, { friends: updatedFriends });
                console.log('Friend added successfully!');
                window.alert("Friend added successfully!");
                getFriendsByUsername(userName)

          }
  
          
  
            

        } else {
          console.log('User not found');
        }
      } else {
        console.log('This name is not in database');
        window.alert("This is not a valid friend name");
      }
    } catch (error) {
      console.error('Error adding friend:', error);
    }
  };
  
  
  
  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      addFriend(new_friend)
      setNewFriend("");
      
    }
  };


   function createFriendCard(data){
       //addProject(data)
       return(<UserCard 
           destination_username = {data}
           source_username = {userName}
           key = {data}
        
       />)
   }
   function createCard(data){
    //addProject(data)
    return(<UserCard 
        destination_username = {data.username}
        source_username = {userName}
        key = {data.username}
     
    />)
}
   const userSignOut = ()=>{
    signOut(auth).then(()=>{
        console.log("Sign out")
        window.location.assign("/")
    })
    .catch((error)=>{
        console.log(error)

    })
}

    
    

    if(authUser !== null){
        const handleSubmit = ()=>{
            addUserToDatabase(new_user,authUser.email)
        }

        
        
        
       getUsernameFromEmail(authUser.email)
       

        if(userName !== ""){
            
           
                getFriendsByUsername(userName)
                
            

            return(
                <ContainerDiv>
                    <MenuDiv>
                    <Title>Users Menu</Title>
                    <LogOutButton onClick={userSignOut}>Logout</LogOutButton>
                    {userName === 'alivehbig' ? all_users.filter(user => user.username !== userName).map(createCard)  :friends.map(createFriendCard) }
                    
                    </MenuDiv>
                    <InputFriend value={new_friend} type="text" id = "friend" onKeyPress={handleKeyPress} onChange={(e)=>setNewFriend(e.target.value)} placeholder="Enter a valid useranme to add friends"></InputFriend>
                </ContainerDiv>
        
            )
    
        }
        return(
            <ContainerDiv>
                <MenuDiv>
                <Title>Users Menu</Title>
                <InputUserName name = "value" type="text" id = "username" onChange={(e)=>setNewUser(e.target.value)} placeholder="Please enter a username to use chatting system"></InputUserName>
                <SubmitButton onClick={handleSubmit}>Set Username</SubmitButton>
                </MenuDiv>
            </ContainerDiv>
    
        )

    }
    else{
        return(
            <ContainerDiv>
                <Title>You have no access to this page</Title>
            </ContainerDiv>
        )
    }
    

    
}