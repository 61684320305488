import React from "react";
import { ContainerCard, Explanation, InnerCard, ProjectLanguages, ProjectLink, ProjectSize, ProjectTitle, ProjectType, StyledEm } from "./cardElements";

export const ProjectCard = (props)=>{
    function LinkControl(link){
        if(link === ""){
            if(props.title === "My Personal Website"){
                return "You are already in this link..."

            }
            else
            {return "No links for this project."}
            
        }
        else{
            return <a rel="noreferrer" target="_blank" href={props.link}>{props.link}</a>
        }
    }
   
    return(
    <ContainerCard>
    <InnerCard>
        <ProjectTitle><em> {props.title} </em></ProjectTitle>
        <ProjectType><StyledEm>Type:</StyledEm> {props.type}</ProjectType>
        <ProjectSize><StyledEm>Size:</StyledEm> {props.size}</ProjectSize>
        <ProjectLanguages><StyledEm>Languages:</StyledEm>{props.languages}</ProjectLanguages>
        <ProjectLink><StyledEm>Project Link:</StyledEm>{LinkControl(props.link)}</ProjectLink>
        <Explanation>
         {props.explanation}
        </Explanation>
        </InnerCard>
      

    </ContainerCard>)
}