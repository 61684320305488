import React, { useEffect, useState } from "react";
import { AdminPanelDiv, ContainerDiv, LastDiv, LogOutButton, MrGA, ProjectsA, SearchA, Title } from "./adminStyle";
import { auth } from "../firebase";

import { onAuthStateChanged, signOut } from "firebase/auth";



export const Admin = () =>{
    const [authUser,setAuthUser] = useState(null);

    useEffect(()=>{
        const listen = onAuthStateChanged(auth,(user)=>{
            if(user){
                setAuthUser(user)
                
            }
            else{
                setAuthUser(null)
            }
        });

        return() =>{
            listen();
        }
    },[])

    const userSignOut = ()=>{
        signOut(auth).then(()=>{
            console.log("Sign out")
            window.location.assign("/")
        })
        .catch((error)=>{
            console.log(error)

        })
    }
    if(authUser !== null && authUser.email !== "ali@gmail.com"){
        return(<ContainerDiv>
            <Title>You have registered to the system but since you are not admin you cannot see anything</Title>
        </ContainerDiv>)
    }
    
    return(
        <ContainerDiv>
            {authUser ? 
            
            <AdminPanelDiv>
                <Title>Welcome Mr.G what do you want to do?</Title>
                
                <SearchA>Add Search Data</SearchA>
                <ProjectsA href="/admin/addproject">Add Project Data</ProjectsA>
                <MrGA>Check Game Scores</MrGA>
                <LogOutButton onClick={userSignOut}>Logout</LogOutButton>
            </AdminPanelDiv>: 
            <Title>You have no access to this page</Title>}
            <LastDiv/>
        </ContainerDiv>
    )
    
}