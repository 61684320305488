import React, { useState } from "react";
import {ContainerDiv,TypewriterDiv,InputName,RegisterImg,SubmitButton, RegisterDiv, InputPassword} from "./registerStyle";
import Typewriter from "typewriter-effect";

import pic from "./admin.gif"
import { auth } from "../firebase";
import { createUserWithEmailAndPassword } from "firebase/auth";

export const Register = () =>{
    const [email,setEmail] = useState("")
    const [password,setPass] = useState("")
    
    const register = (e)=>{
        
        e.preventDefault();
        createUserWithEmailAndPassword(auth,email,password)
        .then((useCredential) => {
            window.location.assign("/menu")
            console.log(useCredential)
            
        }).catch((error)=>{
            console.log(error);
        })
       

    }
    

    return(
        <ContainerDiv>
            <RegisterDiv>
                <RegisterImg src={pic}/>
                <TypewriterDiv>
                <Typewriter 
                options={{
                    autoStart:true,
                    loop:true,
                    delay:40,
                    pauseFor:10000,
                    strings:["Welcome to the register page..."],
                    
                    
                }}>
                </Typewriter>
                </TypewriterDiv>
                <InputName name = "value" type="text" id = "email" placeholder="Email" onChange={(e) =>setEmail(e.target.value)}></InputName>
                <InputPassword name = "value" type="password" id = "password" placeholder="Password should be at least 6 characters" onChange={(e) =>setPass(e.target.value)}></InputPassword>
                <SubmitButton onClick={register}>Register</SubmitButton>
                
            </RegisterDiv>
        </ContainerDiv>
    )
}