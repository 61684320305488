import styled from "styled-components";
import { mainColor } from "../../color";

export const ContainerDiv = styled.div`
&::-webkit-scrollbar {
  display: none;
}
    position:absolute;
    top: 80px;
    bottom: 0;
    left: 0;
    right: 0;
    height: 95%;
    width: 100%;
    margin: 0;
    padding: 0;
    z-index: 0;
    background: black;
    overflow-x: hidden;
    overflow-y: auto;
`;

export const LogOutButton = styled.button`
    &:hover{
          background-color:white;
          color:black;
          font-size:1.4rem;
        }
    position:absolute;
    font-size: 1.3rem;
    width:10%;
    height:2.5rem;
    right:5%;
    top:10%;
    text-align: center;
    margin:0 auto;
    
    background-color:${mainColor};
    color:black;
  
    @media only screen and (max-width: 1320px) {
      font-size: 1.1rem;
      right:2%;
      top:2%;
      height:2rem;
      width:20%;
      &:hover{
      font-size:1.3rem;
    }
  }
`;
export const Title = styled.h1`
    position:relative;
    font-size: 1.5rem;

    width:80%;
    
    top:10%;
    text-align: center;
    margin:0 auto 3rem auto;
    color:${mainColor};
    @media only screen and (max-width: 820px) {
      font-size: 1.6rem;
  }
`;
export const SubmitButton = styled.button`
    position:absolute;
    font-size: 1.5rem;
    width:15%;
    height:2.5rem;
    left:42.5%;
    bottom:20%;
    text-align: center;
    margin:0 auto;
    font-weight:bold;
    background-color:${mainColor};
    color:black;
  
    @media only screen and (max-width: 1320px) {
      font-size: 1.2rem;
      left:30%;
      height:2.2rem;
      width:40%;
  }
`;



export const OutP = styled.p`
    position:absolute;
    font-size: 1.4rem;
    width:80%;
    top:20%;
    left: 10%;
    text-align: center;
    margin:0 auto;
    color:${mainColor};
    @media only screen and (max-width: 1324px) {
      font-size: 1.2rem;
  }
`;

export const MenuDiv = styled.div`
  &::-webkit-scrollbar {
    display: none;
  }
    position:absolute;
    font-size: 1.4rem;
    width:60%;
    height:35rem;
    top:10%;
    left: 20%;
    text-align: center;
    font-weight:bold;
    margin:0 auto;
    color:${mainColor};
    background-color:black;
    border: 5px outset ${mainColor};
    font-family: 'Teko', sans-serif;
    overflow-x: hidden;
    overflow-y: auto;
    @media only screen and (max-width: 1320px) {
      font-size: 1.3rem;
      height:30rem;

      width:70%;
      left:15%;
  }
    @media only screen and (max-width: 1024px) {
      height:25rem;

      font-size: 1.3rem;
      width:80%;
      left:10%;
  }
`;


export const InputUserName = styled.input`
    position:absolute;
    font-size: 1.4rem;
    width:50%;
    bottom:45%;
    left: 25%;
    text-align: center;
    margin:0 auto;
    background-color:black;
    color:${mainColor};
    @media only screen and (max-width: 1320px) {
      font-size: 1.2rem;
      width:80%;
      left:10%;
 
  }
`;

export const InputFriend = styled.input`
    position:absolute;
    font-size: 1.4rem;
    width:50%;
    bottom:20%;
    left: 25%;
    text-align: center;
    margin:0 auto;
    background-color:black;
    color:${mainColor};
    @media only screen and (max-width: 1320px) {
      font-size: 1.2rem;
      bottom:20%;
      width:80%;
      left:10%;
 
  }
`;

export const LastDiv = styled.div`
position: relative;
top:100%;
width: 100%;
height: 5rem;
`;

export const LoginImg = styled.img`
  position: relative;
  width:200px;
  height:auto;
  top:5%;
 
  display: block;
  margin-left: auto;
  margin-right: auto;
  
  margin:0 auto;
`;
export const TypewriterDiv = styled.div`
  position:relative;
  text-align:center;
  top:10%;
  width:100%;
  font-size:1.6rem;
  font-weight: bold;
  color: ${mainColor};
  @media only screen and (max-width: 1024px) {
    top: 10%;

  }
`;