import React,{useState} from "react";
import { ContainerDiv,DataDiv,Explain,SearchDiv, SearchInput, SmallDataA,SmallDataP, Title } from "./searchPageElements";
import { searchData } from "./SearchData";
import { Helmet } from "react-helmet";


export const Search = ()=>{
    const [filteredData,setFiltered] = useState([])
  

      const handleFilter =(event)=>
      {
        const searchWord = event.target.value;
        const newFilter = searchData.filter((value)=>{
            return value.title.toLowerCase().includes(searchWord.toLowerCase());
        });
        if(searchWord === ""){
            setFiltered([]);
        }else{
            setFiltered(newFilter);
        }
        

      }

    return(
        <ContainerDiv>
        <Helmet>
                <title>Search Engine</title>
                <meta name="description" content="A search engine which you can surf on website data."/>
            </Helmet>
        <Title>AVG Search Engine 🔍</Title>
            <SearchDiv>
                <SearchInput name = "search" type = "text" placeholder = "Enter something that you want to find..." onChange ={handleFilter}/>
            </SearchDiv>
            {filteredData.length !== 0 && (
            <DataDiv>
                {filteredData.slice(0,15).map((value,key) =>{
                    return(<SmallDataA rel="noreferrer" target="_blank" name={value.title} href={value.link}>
                    <SmallDataP>{value.title}</SmallDataP>
                    </SmallDataA>
                    );
                })}
            </DataDiv>
            )}
            <Explain>This search engine is created for just fun, I added some data you can search on them.</Explain>
            
        </ContainerDiv>
    )
}