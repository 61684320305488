import styled from "styled-components";
import { mainColor } from "../color";

export const ContainerDiv = styled.div`
&::-webkit-scrollbar {
  display: none;
}
    position:absolute;
    top: 80px;
    bottom: 0;
    left: 0;
    right: 0;
    height: 95%;
    width: 100%;
    margin: 0;
    padding: 0;
    z-index: 0;
    background: black;
    overflow-x: hidden;
    overflow-y: auto;
`;
export const Title = styled.h1`
    position:relative;
    width:80%;
    top:10%;
    text-align: center;
    margin:0 auto;
    color:${mainColor};
    @media only screen and (max-width: 820px) {
      font-size: 1.6rem;
  }
`;
export const SubmitButton = styled.button`
    position:absolute;
    font-size: 1.5rem;
    width:15%;
    height:2.5rem;
    left:42.5%;
    bottom:5%;
    text-align: center;
    margin:0 auto;
    font-weight:bold;
    background-color:${mainColor};
    color:black;
  
    @media only screen and (max-width: 1324px) {
      font-size: 1.2rem;
      left:37.5%;
      height:2.2rem;
      width:25%;
  }
`;
export const NameButton = styled.button`
    position:absolute;
    font-size: 1.5rem;
    width:15%;
    height:2.5rem;
    left:42.5%;
    bottom:25%;
    text-align: center;
    margin:0 auto;
    font-weight:bold;
    background-color:${mainColor};
    color:black;
  
    @media only screen and (max-width: 1320px) {
      font-size: 1.2rem;
      left:30%;
      height:2.2rem;
      width:40%;
  }
`;
export const InputGuess = styled.input`
    position:absolute;
    font-size: 1.4rem;
    width:50%;
    bottom:15%;
    left: 25%;
    text-align: center;
    margin:0 auto;
    background-color:black;
    color:${mainColor};
    @media only screen and (max-width: 1320px) {
      font-size: 1.2rem;

      width:80%;
      left:10%;
  }
`;
export const InputName = styled.input`
    position:absolute;
    font-size: 1.4rem;
    width:50%;
    bottom:40%;
    left: 25%;
    text-align: center;
    margin:0 auto;
    background-color:black;
    color:${mainColor};
    @media only screen and (max-width: 1320px) {
      font-size: 1.2rem;
      width:80%;
      left:10%;
 
  }
`;
export const OutP = styled.p`
    position:absolute;
    font-size: 1.4rem;
    width:80%;
    top:20%;
    left: 10%;
    text-align: center;
    margin:0 auto;
    color:${mainColor};
    @media only screen and (max-width: 1324px) {
      font-size: 1.2rem;
  }
`;
export const LevelP = styled.p`
    position:absolute;
    font-size: 1.5rem;
    width:20%;
    top:5%;
    left: 0;
    text-align: center;
    margin:0 auto;
    color:${mainColor};
    @media only screen and (max-width: 1324px) {
      width:30%;
      font-size: 1.2rem;
  }
`;
export const NameP = styled.p`
    position:absolute;
    font-size: 1.5rem;
    width:40%;
    top:5%;
    left: 30%;
    text-align: center;
    margin:0 auto;
    color:${mainColor};
    @media only screen and (max-width: 1320px) {
      width:35%;
      left: 32.5%;
      font-size: 1.2rem;
  }
`;
export const LivesP = styled.p`
    position:absolute;
    font-size: 1.5rem;
    width:20%;
    top:5%;
    right: 0;
    text-align: center;
    margin:0 auto;
    color:${mainColor};
    @media only screen and (max-width: 1320px) {
      font-size: 1.2rem;
      width:30%;
  }
`;
export const OutPut = styled.div`
    position:absolute;
    font-size: 1.4rem;
    width:60%;
    height:30rem;
    top:15%;
    left: 20%;
    text-align: center;
    font-weight:bold;
    margin:0 auto;
    color:${mainColor};
    background-color:black;
    border: 5px outset ${mainColor};
    font-family: 'Teko', sans-serif;
    @media only screen and (max-width: 1320px) {
      font-size: 1.3rem;
      width:70%;
      left:15%;
  }
    @media only screen and (max-width: 820px) {
      font-size: 1.3rem;
      width:80%;
      left:10%;
  }
`;
export const LastDiv = styled.div`
position: relative;
top:100%;
width: 100%;
height: 5rem;
`;
export const RoboImg = styled.img`
  position: relative;
  width:80px;
  height:80px;
  top:5%;
 
  display: block;
  margin-left: auto;
  margin-right: auto;
  
  margin:0 auto;
  
`;
export const RoboImgInside = styled.img`
  position: relative;
  width:150px;
  height:150px;
  top:5%;
 
  display: block;
  margin-left: auto;
  margin-right: auto;
  
  margin:0 auto;
`;
export const TypewriterDiv = styled.div`
  position:relative;
  text-align:center;
  top:10%;
  width:100%;
  font-size:1.4rem;
  font-weight: bold;
  color: ${mainColor};
  @media only screen and (max-width: 1024px) {
    top: 10%;

  }
`;