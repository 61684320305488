import React, { useState,useEffect } from "react";
import {ContainerDiv,SubmitButton, InputTitle, InputSize, InputType, InputExplanation, InputLanguages, InputLink, AddDiv, Title} from "./addProjectsStyle";
import { collection, addDoc } from "firebase/firestore";
import { db,auth} from "../../firebase";
import { onAuthStateChanged } from "firebase/auth";

export const AddProjects = () =>{
    const [title,setTitle] = useState("")
    const [size,setSize] = useState("")
    const [type,setType] = useState("")
    const [languages,setLanguages] = useState("")
    const [explanation,setExplanation] = useState("")
    const [link,setLink] = useState("")
    const [authUser,setAuthUser] = useState(null);

    useEffect(()=>{
        const listen = onAuthStateChanged(auth,(user)=>{
            if(user){
                setAuthUser(user)
                
            }
            else{
                setAuthUser(null)
            }
        });

        return() =>{
            listen();
        }
    },[])
    const addProject = async () => {
        
    
        try {
            const docRef = await addDoc(collection(db, "projects"), {
              title: title,
              size: size,
              type:type,
              languages:languages,
              explanation:explanation,
              link:link

            });
            console.log("Document written with ID: ", docRef.id);
            window.location.reload();
          } catch (e) {
            console.error("Error adding document: ", e);
          }
    }
    if(authUser === null || authUser.email !== "ali@gmail.com"){
        return(<ContainerDiv>
            <Title>You have no access to this page</Title>
        </ContainerDiv>)
    }

    return(
        <ContainerDiv>
            
            <AddDiv>
                <Title>Add Project</Title>
                
                <InputTitle name = "value" type="text" id = "title" placeholder="Title" onChange={(e)=>setTitle(e.target.value)}></InputTitle>
                <InputSize name = "value" type="text" id = "size"  placeholder="Size" onChange={(e)=>setSize(e.target.value)}></InputSize>
                <InputType name = "value" type="text" id = "type" placeholder="Type" onChange={(e)=>setType(e.target.value)}></InputType>
                <InputLanguages name = "value" type="text" id = "languages" placeholder="Languages" onChange={(e)=>setLanguages(e.target.value)}></InputLanguages>
                <InputLink name = "value" type="text" id = "link" placeholder="Link" onChange={(e)=>setLink(e.target.value)}></InputLink>
                <InputExplanation name = "value" type="text" id = "explanation" placeholder="Explanation" onChange={(e)=>setExplanation(e.target.value)}></InputExplanation>


                <SubmitButton onClick={addProject}>Add Data</SubmitButton>
                
            </AddDiv> : 
            
        </ContainerDiv>
    )
}