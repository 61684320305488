import React from "react";

import {
    BrowserRouter as Router,
    Routes,
    Route,
  } from "react-router-dom";
import { Navbar } from "./Navbar/navbar";
import { Search } from "./Pages/SearchEngine/search";
import { HomePage } from "./Pages/HomePage/HomePage";
import { Projects } from "./Pages/Projects/Projects";
//import { MrG_Preparing } from "./Pages/Mr.G/MrG_Prep";
import { MrG } from "./Pages/Mr.G/MrG";
import { Login } from "./Pages/Login/login";
import { Register } from "./Pages/Register/register";
import { Admin } from "./Pages/Admin/admin";
import { AddProjects } from "./Pages/Admin/Add Projects/addProjects";
import { Menu } from "./Pages/Chat/Menu/menu";
import { ChatPage } from "./Pages/Chat/Chat/chatPage";
  export const Routers= ()=>{
    return(
        <Router>
         <Navbar/>
            <Routes>
            <Route path="/">
                <Route path="/" element = {<HomePage/>}/>
                <Route path="/projects" element = {<Projects/>}/>
                <Route path="/searchEngine" element = {<Search/>}/>
                <Route path="/mrG" element = {<MrG/>}/>
                <Route path="/login" element = {<Login/>}/>
                <Route path="/register" element = {<Register/>}/>
                <Route path="/admin" element = {<Admin/>}/>
                <Route path="/menu" element = {<Menu/>}/>
                <Route path="/chat/:source/:destination" element = {<ChatPage/>}/>

                <Route path="/admin/addProject" element = {<AddProjects/>}/>

                
            </Route>
            </Routes>
        </Router>
    )
  }