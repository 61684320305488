import styled from "styled-components";

export const UCard = styled.div`
    display:inline-block;
    width:30%;
    height:3rem;
    
    border-radius: 2%;
    background-color:#c9c9c9;
    text-align:left;
    margin:3rem 1rem 1rem 1rem; 
    padding:0;
    @media only screen and (max-width: 1024px) {
    
  
    width:80%;
    margin:2rem 1rem 0rem 1rem;  

    height:3rem;
    }
`;
export const InnerCard = styled.div`
    position:relative;
    margin:0 auto;
    padding: 0;
    width: 100%;
    height:100%;
`;
export const NameTitle = styled.h1`
    position:absolute;
    font-size: 1.1rem;

    width:70%;
    left:5%;
    
    top:10%;
    text-align: left;
    margin:0 auto 3rem auto;
    color:black;
    @media only screen and (max-width: 1024px) {
      font-size: 1rem;
  }
`;
export const ChatButton = styled.button`
  &:hover{
            background-color:white;
            color:black;
            font-size:1.1rem;
            font-weight:bold;
          }
    position:absolute;
    font-size: 1rem;
    width:20%;
    height:2rem;
    right:5%;
    top:10%;
    text-align: center;
    margin:0 auto;
    
    background-color:black;
    color:white;
  
    @media only screen and (max-width: 1024px) {
      font-size: 0.9rem;
      right:5%;
      height:2.2rem;
      width:30%;
  }
`;