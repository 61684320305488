import React, {useState ,useEffect} from "react";
import { ProjectCard } from "./components/projectCard";
import { ContainerDiv, LastDiv, Title, TotalProjects } from "./projectsElements";
import {DropDownType,DropDownTypeHeader,DropDownTypeList,DropDownTypeListContainer,ListItemType, TypeFilterTitle} from "./components/DropDowns/Type/Elements"
import { DropDownLanguage,DropDownLanguageHeader,DropDownLanguageListContainer,DropDownLanguageList, ListItemLanguage,LanguageFilterTitle } from "./components/DropDowns/Languages/Elements";
//import { projectsData } from "./components/projectsData";
import { collection, getDocs } from "firebase/firestore";
import {db} from '../firebase';
import { Helmet } from "react-helmet";


export const Projects = ()=>{
    const [isOpen, setIsOpen] = useState(false);
    const [selectedOption, setSelectedOption] = useState(null);
    const options = ["All","Game Development", "Web Application", "Database Systems","Mobile Application","Object Oriented","Cryptography","Socket Programming","Data Science"];
    const toggling = () => setIsOpen(!isOpen);
    const [isLanguageOpen, setIsLanguageOpen] = useState(false);
    const [selectedLanguage, setSelectedLanguage] = useState(null);
    const languages = ["All","C++", "C#", "SQL","PHP","Unity","Flutter","React","Node.js","Mongo","Javascript","Wix","Google Firebase","Python"];
    const togglingLanguage = () => setIsLanguageOpen(!isLanguageOpen);
    const [projectsData,setProjects] = useState([]);
    // const addProject = async (e) => {
    //     //e.preventDefault(); 
    //     var title = e.title
    //     var size = e.size
    //     var type = e.type 
    //     var language = e.languages
    //     var explanation = e.explanation
    //     var link = e.link
       
    //     try {
    //         const docRef = await addDoc(collection(db, "projects"), {
    //           title: title,
    //           size: size,
    //           type:type,
    //           languages:language,
    //           explanation:explanation,
    //           link:link


    //         });
    //         console.log("Document written with ID: ", docRef.id);
    //       } catch (e) {
    //         console.error("Error adding document: ", e);
    //       }
    // }
    
   
    useEffect(()=>{
         getDocs(collection(db, "projects"))
            .then((querySnapshot)=>{               
                const newData = querySnapshot.docs
                    .map((doc) => ({...doc.data(), id:doc.id }));
                setProjects(newData);                
                console.log(projectsData, newData);
            })
    }, [])




    function createCard(data){
        //addProject(data)
        return(<ProjectCard 
            title = {data.title}
            type = {data.type}
            size = {data.size}
            explanation = {data.explanation}
            languages = {data.languages}
            link = {data.link}
           
        />)
    }
    function mapTypeData(project) {
        
        if(project.type === selectedOption){
            return 1;
        }
        if(selectedOption === "All" || selectedOption === null){
            return 1;
        }
        
      }
      function mapLanguageData(project) {
        
        if(selectedLanguage === "All" || selectedLanguage === null){
            return 1;
        }
        if(project.languages.toLowerCase().includes(selectedLanguage.toLowerCase())){
            return 1;
        }
        
        
      }
    const onOptionClicked = value => () => {
        setSelectedOption(value);
        setIsOpen(false);
        console.log(selectedOption);
       
      };
      const onOptionLanguageClicked = value => () => {
        setSelectedLanguage(value);
        setIsLanguageOpen(false);
        console.log(selectedLanguage);
       
      };
    return(<ContainerDiv>
                <Helmet>
                <title>Projects</title>
                <meta name="description" content="A lot of different projects with various programming languages"/>
                </Helmet>
                <Title>Project Page</Title>
                <TotalProjects>Total Projects: {projectsData.length}</TotalProjects>
                {projectsData.filter((data)=> mapTypeData(data) === 1 && mapLanguageData(data) === 1 ).map(createCard)}
                <TypeFilterTitle>Type Filter</TypeFilterTitle>
                <DropDownType>
                <DropDownTypeHeader onClick={toggling}> {selectedOption||"All"}
                 </DropDownTypeHeader>
                 {isOpen && (
                     <DropDownTypeListContainer>
                         <DropDownTypeList>
                            {options.map(option => (
                            <ListItemType onClick={onOptionClicked(option)} key={Math.random()}>
                            {option}
                            </ListItemType>
                         ))}
                        </DropDownTypeList>
                    </DropDownTypeListContainer>
                )}
                </DropDownType>

                <LanguageFilterTitle>Language Filter</LanguageFilterTitle>
                <DropDownLanguage>
                <DropDownLanguageHeader onClick={togglingLanguage}> {selectedLanguage||"All"}
                 </DropDownLanguageHeader>
                 {isLanguageOpen && (
                     <DropDownLanguageListContainer>
                         <DropDownLanguageList>
                            {languages.map(option => (
                            <ListItemLanguage onClick={onOptionLanguageClicked(option)} key={Math.random()}>
                            {option}
                            </ListItemLanguage>
                         ))}
                        </DropDownLanguageList>
                    </DropDownLanguageListContainer>
                )}
                </DropDownLanguage>
                             
                <LastDiv></LastDiv>

            </ContainerDiv>
        )
}