import React from "react";
import { SCard,ChatButton, InnerCard, NameTitle,DateTitle, MessageP } from "./sourceStyle";


export const SourceCard = (props) =>{

    const formattedDay = new Date(props.day.seconds * 1000).toLocaleString();
    return(
    <SCard>
        <InnerCard>
            <NameTitle>{props.username}</NameTitle>
            <DateTitle>{formattedDay}</DateTitle>
            <MessageP> {props.message}</MessageP>
        </InnerCard>
    </SCard>
    )
}