import styled from "styled-components";

export const DCard = styled.div`
    &::-webkit-scrollbar {
    display: none;
    }
    display:relative;
    width:60%;
    height:8rem;
    
    border-radius: 1%;
    background-color:#c9c9c9;
    text-align:left;
    margin:2rem 2% 2rem 2%; 
    padding:0;
    left:10%;
    overflow-x: hidden;
    overflow-y: auto;
    @media only screen and (max-width: 1024px) {
    
    left:10%;
    width: 70%;
    margin:2rem 2% 2rem 2%;
    height: 6rem;
    }
`;
export const InnerCard = styled.div`
    position:relative;
    margin:0 auto;
    padding: 0;
    width: 100%;
    height:100%;
`;
export const NameTitle = styled.h1`
    position:absolute;
    font-size: 1.1rem;

    width:30%;
    left:2%;
    
    top:5%;
    text-align: left;
    margin:0 auto 3rem auto;
    color:black;
    @media only screen and (max-width: 1024px) {
      font-size: 0.8rem;
  }
`;
export const DateTitle = styled.h1`
    position:absolute;
    font-size: 1.1rem;

    width:30%;
    right:2%;
    
    top:5%;
    text-align: right;
    margin:0 auto 3rem auto;
    color:black;
    @media only screen and (max-width: 1024px) {
      width:50%;
      font-size: 0.8rem;
  }
`;
export const MessageP = styled.p`
    position:absolute;
    font-size: 1rem;

    width:90%;
    right:5%;
    
    top:25%;
    text-align: left;
    margin:0 auto 3rem auto;
    color:black;
    @media only screen and (max-width: 1024px) {
      font-size: 0.7rem;
  }
`;
export const ChatButton = styled.button`
    position:absolute;
    font-size: 1rem;
    width:20%;
    height:2rem;
    right:5%;
    top:10%;
    text-align: center;
    margin:0 auto;
    
    background-color:black;
    color:white;
  
    @media only screen and (max-width: 1320px) {
      font-size: 1.2rem;
      left:30%;
      height:2.2rem;
      width:40%;
  }
`;