import styled from "styled-components";
import { mainColor } from "../color";
export const ContainerDiv = styled.div`
&::-webkit-scrollbar {
  display: none;
}
    position:absolute;
    top: 80px;
    bottom: 0;
    left: 0;
    right: 0;
    height: 95%;
    width: 100%;
    margin: 0;
    padding: 0;
    z-index: 0;
    text-align:center;
    
    background: black;
    overflow-x: hidden;
    overflow-y: auto;
`;
export const LastDiv = styled.div`
position: relative;
top:100%;
width: 100%;
height: 5rem;
`;
export const Title = styled.h1`
    position:relative;
    width:30%;
    margin:0 auto;
    color:${mainColor};
    @media only screen and (max-width: 820px) {
      font-size: 1.5rem;
  }
`;
export const TotalProjects = styled.h1`
    position:absolute;
    width:30%;
    left:35%;
    top:7%;
    font-size:1.3rem;
    margin:0 auto;
    color:${mainColor};
    @media only screen and (max-width: 1020px) {
      font-size: 0;
  }
`;

