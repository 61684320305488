import styled from "styled-components";
import { mainColor } from "../../color";

export const ContainerDiv = styled.div`
&::-webkit-scrollbar {
  display: none;
}
    position:absolute;
    top: 80px;
    bottom: 0;
    left: 0;
    right: 0;
    height: 95%;
    width: 100%;
    margin: 0;
    padding: 0;
    z-index: 0;
    background: black;
    overflow-x: hidden;
    overflow-y: auto;
`;

export const ChatDiv = styled.div`
    position:absolute;
    font-size: 1.4rem;
    width:70%;
    height:40rem;
    top:10%;
    left: 15%;
    font-weight:bold;
    margin:0 auto;
    color:${mainColor};
    background-color:black;
    border: 5px outset ${mainColor};
    font-family: 'Teko', sans-serif;
    overflow-x: hidden;
    overflow-y: auto;
    @media only screen and (max-width: 1320px) {
        &::-webkit-scrollbar {
        display: none;
        }
      font-size: 1.2rem;
      height:30rem;
      width:80%;
      left:10%;
  }
    @media only screen and (max-width: 1024px) {
      &::-webkit-scrollbar {
        display: none;
        }
      height:25rem;

      font-size: 1.2rem;
      width:98%;
      left:1%;
  }
`;

export const Title = styled.h1`
    position:relative;
    font-size: 1.5rem;

    width:80%;
    
    top:5%;
    text-align: center;
    margin:0 auto 3rem auto;
    color:${mainColor};
    @media only screen and (max-width: 820px) {
      font-size: 1.1rem;
  }
`;
export const ChatInput = styled.input`
    position:absolute;
    font-size: 1.4rem;
    width:50%;
    bottom:10%;
    left: 25%;
    text-align: center;
    margin:0 auto;
    background-color:black;
    color:${mainColor};
    @media only screen and (max-width: 1320px) {
      font-size: 1.2rem;
      width:80%;
      left:10%;
 
  }
`;
export const Refresh = styled.button`
    &:hover{
          background-color:white;
          color:black;
          font-size:1.4rem;
        }
    position:absolute;
    font-size: 1.3rem;
    width:10%;
    height:2.5rem;
    right:15%;
    top:5%;
    text-align: center;
    margin:0 auto;
    
    background-color:${mainColor};
    color:black;
  
    @media only screen and (max-width: 1320px) {
      font-size: 1.2rem;
      right:10%;
      height:2.2rem;
      width:10%;
      &:hover{
      font-size:1.3rem;
    }
    
  }
  @media only screen and (max-width: 1024px) {
      font-size: 1rem;
      right:5%;
      height:2.2rem;
      width:15%;
      &:hover{
      font-size:1.1rem;
    }
    
  }
`;

export const Delete = styled.button`
    &:hover{
          background-color:white;
          color:black;
          font-size:1.4rem;
        }
    position:absolute;
    font-size: 1.3rem;
    width:10%;
    height:2.5rem;
    left:15%;
    top:5%;
    text-align: center;
    margin:0 auto;
    
    background-color:red;
    color:black;
  
    @media only screen and (max-width: 1320px) {
      font-size: 1.2rem;
      left:10%;
      height:2.2rem;
      width:10%;
      &:hover{
      font-size:1.3rem;
    }
    
  }
  @media only screen and (max-width: 1024px) {
      font-size: 1rem;
      left:5%;
      height:2.2rem;
      width:15%;
      &:hover{
      font-size:1.1rem;
    }
    
  }
`;