import React,{useState,useEffect} from "react";
import { ContainerDiv, InputGuess, OutPut, SubmitButton,RoboImg, OutP, LevelP, LivesP, InputName, NameButton, RoboImgInside,TypewriterDiv, NameP, Title } from "./MrGstyle";
import pic from "./robot-idle.gif"
import crown from "./crown.gif"
import Typewriter from "typewriter-effect";
import { Helmet } from "react-helmet";


export const MrG = () =>{
    

    const [output,setOut] = useState("")
    const [the_num,setNum] = useState(0)
    const [remaining,setRem] = useState(0)
    const [level,setLevel] = useState(0)
    const [butName,setBut] = useState("Submit")
    const [playerName,setPlayer]=useState("")
    var name;
    const [guess,setGuess] = useState()
    useEffect(() => {
        var secret = Math.floor(Math.random() * 10);
        setNum(secret)
        
        setRem(3)
        
      }, []);
    
    
    // const handleChange = (event)=>{
    //     guess = event.target.value;
    // }
    const inputName = (event) =>{
        name = event.target.value;
    }
    const handleName = (event)=>{
        setPlayer(name)
        setOut("Welcome " +name+", I am the Mr.G, it is impossible to beat me but you can try it. HA HA HA! So lets start the game, I just picked a number between 0-10, can you guess it?")

    }
    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
          handleSubmit()
          
        }
      };
    const handleSubmit = ()=>{
        if(remaining > 0 && butName !== "Restart"){
            
            if(parseInt(guess) === the_num){
                // out.innerHTML = "Thats right!"
                if(level === 0){
                    var secret1 = Math.floor(Math.random() * 30);
                    setNum(secret1)
                    setRem(remaining+2)
                    setLevel(1)
                    setOut("Wow, thats right but this level was easy! Now, you are in level 1 and I give you 2 more chances, I picked up a number between 0-30, can you guess it?")
                }
                else if(level === 1){
                    var secret2 = Math.floor(Math.random() * 50);
                    setNum(secret2)
                    setRem(remaining+1)
                    setLevel(2)
                    setOut("How this is possible???, your guess is right. Now, you are in level 2 and I give you 1 more chances, I picked up a number between 0-50, can you guess it?(Of course not HA HA HA !)")
                }
                else if(level === 2){
                    setOut("Noooo, how do you beat me, thats impossible!!!! ERROR ERROR ERROR...")
                    setBut("Restart")
                    

                }
            }
            else{
                
                if(parseInt(guess) > the_num){
                    // out.innerHTML = "Go down!"
                    
                    if(output === "Go down looser!"){
                        if(remaining === 1){
                            setRem(0)
                            setBut("Restart")
                            setOut("You lose the game HA HA HA! I picked "+ the_num +" Do you want to try again then use restart button!!")


                        }
                        else{
                            setRem(remaining-1)
                            setOut("Go down again loooser!")
                        }
                        
                    }
                    else{
                        if(remaining === 1){
                            setRem(0)
                            setBut("Restart")
                            setOut("You lose the game HA HA HA! I picked "+ the_num +" Do you want to try again then use restart button!!")


                        }
                        else{
                            setRem(remaining-1)
                            setOut("Go down looser!")  
                        }
                          
                    }
                    
                    
                }
                else if(parseInt(guess) < the_num){
                    // out.innerHTML = "Go Up!"
                   
                    if(output === "Go up freak!"){
                        if(remaining === 1){
                            setRem(0)
                            setBut("Restart")
                            setOut("You lose the game HA HA HA! I picked "+ the_num +" Do you want to try again then use restart button!!")


                        }
                        else{
                            setRem(remaining-1)
                            setOut("Go up again freak!") 
                        }
                        
                    }
                    else{
                        if(remaining === 1){
                            setRem(0)
                            setBut("Restart")
                            setOut("You lose the game HA HA HA! I picked "+ the_num +" Do you want to try again then use restart button!!")


                        }
                        else{
                            setRem(remaining-1)
                            setOut("Go up freak!")   
                        }
                         
                    }
                      
                }
                else{
                    // out.innerHTML = "Do you think it is a number?"   
                    setOut("Do you think it is a number? I thought you would be a great enemy but I see nothing!")              
                }

                          
            }

        }
        else{
            if(butName === "Restart"){
                window.location.reload()
            }
            setBut("Restart")
            setOut("You lose the game HA HA HA! I picked "+ the_num +" Do you want to try again then use restart button!!")
            
        }
    }
    if(playerName === ""){
        return(
            <ContainerDiv>
            <Helmet>
                <title>Mr.G's Game</title>
                <meta name="description" content="A hard probablity game with three levels"/>
            </Helmet>
                <OutPut>
                    <RoboImgInside src={pic}/>
                    <TypewriterDiv>
                    <Typewriter 
                    options={{
                        autoStart:true,
                        loop:true,
                        delay:40,
                        strings:["Mr.G is waiting for you..."]
                        
                    }}>
                    </Typewriter>
                    </TypewriterDiv>
                    <InputName name = "value" type="text" id = "name" placeholder="Enter your name?" onChange={inputName}></InputName>
                    <NameButton onClick={handleName}>Start</NameButton>
                    
                </OutPut>
            </ContainerDiv>
        )
    }
    if(output === "Noooo, how do you beat me, thats impossible!!!! ERROR ERROR ERROR..."){
        return(
            <ContainerDiv>
        
        <OutPut>
            <RoboImgInside src={crown}/>
            <TypewriterDiv>
            <Typewriter 
            options={{
                autoStart:true,
                loop:true,
                delay:40,
                strings:["You win the game " + playerName + ", congratulations..."]
                
            }}>
            </Typewriter>
            </TypewriterDiv>
            <NameButton onClick={handleSubmit}>{butName}</NameButton>
            
        </OutPut>
    </ContainerDiv>
        )
    }
 

    return(
    <ContainerDiv>
        <RoboImg src={pic}/>
        <OutPut>
            <LevelP>Level: {level}⭐</LevelP>
            <NameP>{playerName}</NameP>
            <LivesP>Lives: {remaining}❤️</LivesP>
            <InputGuess value={guess} name = "value" type="text" id = "guess" onKeyPress={handleKeyPress} placeholder="What is your guess?" onChange={(e)=>setGuess(e.target.value)}></InputGuess>
            <SubmitButton onClick={handleSubmit}>{butName}</SubmitButton>
            <OutP id="output">{output}</OutP>
            
        </OutPut>
    </ContainerDiv>
    )

}