import styled from "styled-components";
import { mainColor,secondColor,darkColor, darkGreyColor, greybackColor, lighterColor } from "../../color";
export const ContainerCard = styled.div`
    display:inline-block;
    width:40rem;
    height:30rem;
    border-radius: 2%;
    border: solid 2px ${darkColor};
    background-color:black;
    text-align:left;
    margin:4rem 3rem; 
    padding:0;
    transition: width 0.3s, height 0.3s,border 0.3s;
    &:hover{
        width:42rem;
        height:32rem;
        border: solid 2px ${mainColor};
        h1{
            color: ${mainColor};
            font-size:1.5rem;
            transition: color 0.3s,font-size 0.3s;

        }
        h2{
            color:${greybackColor};
            font-size:1.2rem;
            transition: color 0.3s,font-size 0.3s;

        }
        p{
            color:${greybackColor};
            font-size:1.1rem;
            transition: color 0.3s,font-size 0.3s;

        }
        em{
            color: ${secondColor};
            transition: color 0.3s,font-size 0.3s;

        }
        

    }
    @media only screen and (max-width: 850px) {
    
    left:0;
    width: 23.5rem;
    margin:2rem auto; 

    height: 25rem;
    &:hover{
        width:24.5rem;
        height:26rem;
        border: solid 2px ${mainColor};
        h1{
            color: ${mainColor};
            font-size:1.1rem;
            transition: color 0.3s,font-size 0.3s;

        }
        h2{
            color:${greybackColor};
            font-size:0.75rem;
            transition: color 0.3s,font-size 0.3s;

        }
        p{
            color:${greybackColor};
            font-size:0.8rem;
            transition: color 0.3s,font-size 0.3s;

        }
        em{
            color: ${secondColor};
            transition: color 0.3s,font-size 0.3s;

        }
        

    }
    }
`;
export const InnerCard = styled.div`
    position:relative;
    margin:0 auto;
    padding: 0;
    width: 100%;
    height:100%;
`;
export const ProjectTitle = styled.h1`
    position:absolute;
    top:5%;
    width:90%;
    left:5%;
    text-align:center;
    margin:0 auto;
    font-size: 1.4rem;
    color:${darkColor};
    @media only screen and (max-width: 850px) {
    font-size: 1rem;


    }
`;
export const ProjectType = styled.h2`
    position:absolute;
    display:flex;
    top:15%;
    left:5%;    

    width:90%;
    font-style: italic;
    margin:0 auto;
    font-size: 1.1rem;
    
    color:${darkGreyColor};
    @media only screen and (max-width: 850px) {
    font-size: 0.7rem;


    }
`;

export const ProjectSize = styled.h2`
    position:absolute;
    display:flex;
    top:22%;
    left:5%;    

    width:90%;
    margin:0 auto;
    font-style: italic;

    font-size: 1.1rem;
    color:#6f6f6f;
    @media only screen and (max-width: 850px) {
    font-size: 0.7rem;

    }
`;
export const ProjectLanguages = styled.h2`
    position:absolute;
    display:flex;
    top:29%;
    left:5%;    

    width:90%;
    margin:0 auto;
    font-style: italic;

    font-size: 1.1rem;
    color:#6f6f6f;
    @media only screen and (max-width: 850px) {
    font-size: 0.7rem;


    }
`;
export const ProjectLink = styled.h2`
    position:absolute;
    display:flex;
    top:36%;
    width:90%;
    left:5%;    
    
    margin:0 auto;
    font-style: italic;

    font-size: 1.1rem;
    color:#6f6f6f;
    @media only screen and (max-width: 850px) {
    font-size: 0.7rem;


    }
`;
export const StyledEm = styled.em`
    color:${darkColor};
    
`;
export const Explanation = styled.p`
    position:absolute;
    margin:0 ;
    padding:0;
    font-size:1rem;
    width:80%;
    top:50%;
    left:5%;
    color:#6f6f6f;
    @media only screen and (max-width: 850px) {
    top:45%;
    font-size: 0.75rem;
    }
`;

