import React,{useState,useEffect} from "react";
import { useParams } from 'react-router-dom';
import { ChatDiv, ContainerDiv, Refresh, Title, ChatInput, Delete } from "./chatPageStyle";
import { DesCard } from "./DestinationChat/dest";
import { SourceCard } from "./SourceChat/source";
import { auth,db } from "../../firebase";
import { onAuthStateChanged,signOut } from "firebase/auth";
import { addDoc, collection, query, where, getDocs ,deleteDoc} from 'firebase/firestore';


export const ChatPage = ()=>{
    const { destination, source } = useParams();
    const[message,setMessage] = useState("");
    const [userName,setUserName] = useState("");
    const[total_chat,setTotal] = useState([]);
    const [authUser,setAuthUser] = useState(null);
    const getUsernameFromEmail = async (email) => {
        try {
          const usersRef = collection(db, 'usernames');
          const q = query(usersRef, where('email', '==', email));
          const snapshot = await getDocs(q);
      
          if (!snapshot.empty) {
            const userData = snapshot.docs[0].data();
            setUserName(userData.username);
            // Do something with the username
            console.log(userName);
          } else {
            // Email not found
            console.log('Email not found');
          }
        } catch (error) {
          console.error('Error fetching username from email:', error);
        }
      };
      const getSourceChats = async (sor, des) => {
        try {
          const usersRef = collection(db, 'chat');
          const q = query(usersRef, where('source', '==', sor), where('destination', '==', des));
          const snapshot = await getDocs(q);
          var sorChat = []
      
          if (!snapshot.empty) {
            sorChat = snapshot.docs.map((doc) => doc.data());
            
            
          } else {
            // Chats not found
            console.log('Sor chats not found');
          }

          const q2 = query(usersRef, where('source', '==', des), where('destination', '==', sor));
          const snapshot2 = await getDocs(q2);
          var desChat = []
          if (!snapshot2.empty) {
            desChat = snapshot2.docs.map((doc) => doc.data());
           
            
          } else {
            // Chats not found
            console.log('Des chats not found');
          }
          setTotal(sorChat.concat(desChat));

        } catch (error) {
          console.error('Error fetching chats:', error);
        }
      };
      

    useEffect(()=>{
        const listen = onAuthStateChanged(auth,(user)=>{
            if(user){
                setAuthUser(user)
                getSourceChats(source, destination)
                
                
                
            }
            else{
                setAuthUser(null)
            }
        });

        return() =>{
            listen();
        }
    },[])
    const handleRef = ()=>{        
        getSourceChats(source, destination)
    }
    
    const handleKeyPress = (event) => {
      if (event.key === 'Enter') {
        event.preventDefault();
        addProject()
        setMessage("");
        handleRef();
        
      }
    };
    const addProject = async () => {
        
    
      try {
          const docRef = await addDoc(collection(db, "chat"), {
            destination: destination,
            source: source,
            chat:message,
            day: new Date(),

          });
          console.log("Document written with ID: ", docRef.id);
          
        } catch (e) {
          console.error("Error adding document: ", e);
        }
  }
    
    
    function createCard(data){
        if(data.source === source){
            
            return(<SourceCard
                username = {data.source}
                message = {data.chat}
                day = {data.day}
                key = {data.id}
             
            />)
        }
        else if(data.source === destination){
            return(<DesCard
                username = {data.source}
                message = {data.chat}
                day = {data.day}
                key = {data.id}
             
            />)
        }
        
    }
    const deleteMyMessages = async () => {
      try {
        const collectionRef = collection(db, 'chat');
        const q = query(collectionRef, where('source', '==', source),where('destination', '==', destination));
        const querySnapshot = await getDocs(q);
    
        querySnapshot.forEach((doc) => {
          deleteDoc(doc.ref)
            .then(() => {
              console.log('Document successfully deleted!');
              handleRef()
            })
            .catch((error) => {
              console.error('Error deleting document:', error);
            });
        });
      } catch (error) {
        console.error('Error fetching documents:', error);
      }
    };
    
    var count = 0;
    if(authUser !== null){
        if(count === 0){
            getUsernameFromEmail(authUser.email)
            count+=1
        }
        if(userName === source){
            return(
                <ContainerDiv>
                <Title>{source}-{destination} ChatPage</Title>
                <Refresh onClick={handleRef}>Refresh</Refresh>
                <Delete onClick={deleteMyMessages}>Delete</Delete>
                    <ChatDiv>
                        
                        {total_chat.sort((a, b) => a.day - b.day).map(createCard)}
                    </ChatDiv>
                    <ChatInput value={message} type="text" id = "username" onKeyPress={handleKeyPress} onChange={(e)=>setMessage(e.target.value)} placeholder="Message..."></ChatInput>
                </ContainerDiv>
            )

        }
        else{
            return(
                <ContainerDiv>
                    <Title>It is not true to reach other peoples messages</Title>
                </ContainerDiv>
            )
        }
        

    }
    else{
        return(
            <ContainerDiv>
                <Title>It is not true to reach other peoples messages</Title>
            </ContainerDiv>
        )
        
    }
}