import styled from "styled-components";
import { mainColor, secondColor } from "../../../../color";
export const TypeFilterTitle = styled.h1`
    position:absolute;
    width:12rem;
    right:20%;
    text-align:center;
    top:0;
    font-size:1.2rem;
    color:${mainColor};
    @media only screen and (max-width: 820px) {
      width:8rem;
    font-size:1rem;


      right:5%;
  }
`;

export const DropDownType = styled("div")`
  width: 12rem;
  z-index:10;
  
  position: absolute;
  right: 20%;
  top: 5%;
  margin: 0 auto;
  color:black;
  @media only screen and (max-width: 820px) {
      right:5%;
      width:8rem;
  }
`;

export const DropDownTypeHeader = styled("div")`
  margin-bottom: 0.8em;
  
  text-align: left;
  padding: 0.4em 2em 0.4em 1em;
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.15);
  font-weight: bold;
  font-size: 1rem;
  color:${mainColor};
  background: black;
  border: solid 2px ${mainColor};
  @media only screen and (max-width: 820px) {
      font-size: 0.8rem;
  }
`;

export const DropDownTypeListContainer = styled("div")``;

export const DropDownTypeList = styled("ul")`
  padding: 0;
  margin: 0;
  padding-left: 1em;
  background: black;
  border: 1px solid ${secondColor};
  box-sizing: border-box;
  text-align: left;

  color:${secondColor};
  font-size: 1rem;
  font-weight: 500;
  &:first-child {
    padding-top: 0.8em;
  }
  @media only screen and (max-width: 820px) {
      font-size: 0.8rem;
  }
`;

export const ListItemType = styled("li")`
  list-style: none;
  transition: font-size 0.1s,font-weight 0.1s,color 0.1s;
  &:hover{
    font-size:1.2rem;
    font-weight:bold;
    color:${mainColor};
  }
  @media only screen and (max-width: 820px) {
    &:hover{
    font-size:0.9rem;
  }
  }
  margin-bottom: 0.8em;
`;