import styled from "styled-components";
import { mainColor,secondColor,darkColor } from "../../../color";

export const AboutMeDiv = styled.div`
  @keyframes slideInLeft {
    0% {
      transform: translateX(-100%);
    }
    100% {
      transform: translateX(0);
    }
  }
  
    position: relative;
    top: 30%;
    left:15%;
    width: 50rem;
    height:20rem;
    border-radius: 2%;
    background-color:black;
    animation-name: slideInLeft;
    border: solid 2px ${darkColor};

    animation-duration: 2s;
    transition: font-size 0.5s,height 0.3s, width 0.3s;
    &:hover{
    border: solid 5px ${mainColor};
    font-size: 1.4rem;
    height:25rem;
    p{
      transition: font-size 0.5s;
      font-size:1.5rem;
      color:${secondColor};
    }
    
    }
    
    
    @media only screen and (max-width: 1324px) {
    top: 30%;
    left:0;
    width: 35rem;
    margin: 0 auto;
    height: 15rem;
    &:hover{
    border: solid 5px ${mainColor};
    font-size: 1.4rem;
    height:17rem;
    p{
      transition: font-size 0.5s;
      font-size:1.1rem;
      color:${secondColor};
    }
    
    }
  }
  @media only screen and (max-width: 820px) {
    
    top: 30%;
    left:0;
    width: 22rem;
    margin: 0 auto;
    height: 13rem;
    &:hover{
    border: solid 5px ${mainColor};
    font-size: 1.3rem;
    height:15rem;
    width:23.5rem;
    p{
      transition: font-size 0.5s;
      font-size:0.9rem;
      color:${secondColor};
    }
    
    }
  }
`;
export const Title = styled.h1`
    position: absolute;
    top: 5%;
    left:5%;
    width: 35%;
    color: ${mainColor};
    @media only screen and (max-width: 1324px) {
        font-size: 1.6rem;
  }
  @media only screen and (max-width: 820px) {
    font-size: 1.2rem;
    
  }
`;
export const Explain = styled.p`

    position:absolute;
    top: 20%;
    left: 5%;
    width: 90%;
    font-size:1.4rem;
    line-height: 1.8;
    color:${darkColor};
    @media only screen and (max-width: 1324px) {
        font-size: 1rem;
  }
  @media only screen and (max-width: 820px) {
    font-size: 0.8rem;
    
  }
`;