import styled from "styled-components";
import { Input } from "reactstrap";
import { mainColor } from "../color";
export const ContainerDiv = styled.div`
&::-webkit-scrollbar {
  display: none;
}
    position:absolute;
    top: 80px;
    bottom: 0;
    left: 0;
    right: 0;
    height: 95%;
    width: 100%;
    margin: 0;
    padding: 0;
    z-index: 0;
    background: black;
    overflow-x: hidden;
    overflow-y: auto;
`;
export const Title = styled.h1`
  position: absolute;
  top:10%;
  left: 20%;
  right: 20%;

  color: ${mainColor};
  font-weight: bold;
  text-align: center;

  font-size: 2.6rem;
  font-family: Cambria, Cochin, Georgia, Times, "Times New Roman", serif;
  @media only screen and (max-width: 1400px) {
    font-size: 2rem;

  }
  @media only screen and (max-width: 820px) {
      font-size: 1.6rem;
  }
`;
export const Explain = styled.h1`
  position: absolute;
  top:30%;
  left: 20%;
  right: 20%;

  color: #6f6f6f;
  font-weight: bold;
  text-align: center;

  font-size: 1.2rem;
  font-family: Cambria, Cochin, Georgia, Times, "Times New Roman", serif;
  @media only screen and (max-width: 1400px) {
    font-size: 1rem;

  }
  @media only screen and (max-width: 820px) {
      font-size: 0.8rem;
  }
`;
export const SearchDiv = styled.div`
  position: absolute;
  top:20%;
  left:20%;
  right:20%;
  width:60%;
  margin: 0 auto;
  padding: 0;
 
  background:#c9c9c9;
  @media only screen and (max-width: 1400px) {
      width:70%;
      left:15%;
      right:15%;
  }
  @media only screen and (max-width: 820px) {
      width:80%;
      left:10%;
      right:10%;
  }

`;
export const DataDiv = styled.div`
  position: absolute;
  top:26%;
  left:20%;
  right:20%;
  width:60%;
  height: 300px;
  margin: 0 auto;
  padding: 0;
  overflow: hidden;
  overflow-y:auto;
  background:#c9c9c9;
  z-index:3;
  @media only screen and (max-width: 1400px) {
    top:30%;
      width:70%;
      left:15%;
      right:15%;
  }
  @media only screen and (max-width: 820px) {
      width:80%;
      left:10%;
      right:10%;
  }

  
`;
export const SmallDataA = styled.a`
  &:hover {
    background-color: lightgrey;
  }
  width:100%;
  color:black;
  display:flex;
  text-decoration: 0;
  margin-top: 10px;
  padding: 0;
`;
export const SmallDataP = styled.p`
  width:100%;
  color:black;
  font-weight:bold;
  display:flex;
  margin-top: 10px;
  margin-left: 5px;

  padding: 0;
`;
export const SearchInput = styled(Input)`
    width:100%;
    background:#c9c9c9;

    font-size:1.1rem;
    font-weight:bold;
    height:3rem;
    display:flex;
    @media only screen and (max-width: 820px) {
      font-size: 0.9rem;
      height:2.5rem;
  }
`;
