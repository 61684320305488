import styled from "styled-components";
import { mainColor,secondColor,darkColor } from "../../../color";

export const ContactMeDiv = styled.div`
  @keyframes slideInLeft {
      0% {
        transform: translateX(-100%);
      }
      100% {
        transform: translateX(0);
      }
    }
    position: relative;
    top: 40%;
    left:25%;
    width: 50rem;
    height:20rem;
    border-radius: 2%;
    background-color:black;
    border: solid 2px ${darkColor};

    animation-name: slideInLeft;
    animation-duration: 2s;
    transition: font-size 0.5s,height 0.3s, width 0.3s;
    &:hover{
    border: solid 5px ${mainColor};
    font-size: 1.4rem;
    height:25rem;
    h2{
      transition: font-size 0.5s;
      font-size:1.5rem;
      color:${secondColor};
      
    }
    em{
      transition: font-size 0.5s;
      font-size:1.5rem;
      color:${mainColor};
    }
    a{
      transition: font-size 0.5s;
      font-size:1.5rem;
      color:${secondColor};
      
    }
    }

    @media only screen and (max-width: 1324px) {
    top: 50%;
    left:0;
    width: 35rem;
    margin: 0 auto;
    height: 15rem;

    &:hover{
    border: solid 5px ${mainColor};
    font-size: 1.4rem;
    height:18rem;
    h2{
      transition: font-size 0.5s;
      font-size:1.1rem;
      color:${secondColor};
      
    }
    a{
      transition: font-size 0.5s;
      font-size:1.1rem;
      color:${secondColor};
      
    }
    em{
      transition: font-size 0.5s;
      font-size:1.1rem;
      color:${mainColor};

    }
    }
  }
  @media only screen and (max-width: 820px) {
    top: 50%;
    left:0;
    width: 22rem;
    margin: 0 auto;
    height: 13rem;
    &:hover{
    border: solid 5px ${mainColor};
    font-size: 1.3rem;
    width: 23.5rem;

    height:15rem;
    h2{
      transition: font-size 0.5s;
      font-size:1rem;
      color:${secondColor};
    }
    a{
      transition: font-size 0.5s;
      font-size:1rem;
      color:${secondColor};
    }
    em{
      transition: font-size 0.5s;
      font-size:1rem;
      color:${mainColor};
      
    }
    }
  }
`;
export const Title = styled.h1`
    position: absolute;
    top: 5%;
    left:5%;
    width: 35%;
    color: ${mainColor};
    @media only screen and (max-width: 1324px) {
        font-size: 1.6rem;
  }
  @media only screen and (max-width: 820px) {
    font-size: 1.2rem;
    
  }
`;
export const Emp = styled.em`
    color:${secondColor};
    text-decoration:none;
`;

export const Email1 = styled.h2`
    position: absolute;
    top: 30%;
    left:5%;
    width: 90%;
    color: ${darkColor};
    font-size: 1.4rem;
    font-style: italic;
    @media only screen and (max-width: 1324px) {
        font-size: 1rem;
  }
  @media only screen and (max-width: 820px) {
    font-size: 0.9rem;
    
  }
`;
export const Email2 = styled.h2`
    position: absolute;
    top: 45%;
    left:5%;
    width: 90%;
    color: ${darkColor};
    font-size: 1.4rem;
    font-style: italic;

    @media only screen and (max-width: 1324px) {
        font-size: 1rem;
  }
  @media only screen and (max-width: 820px) {
    font-size: 0.9rem;

    
  }
`;
export const LinkedIn = styled.a`
    position: absolute;
    top: 60%;
    left:5%;
    width: 90%;
    color: ${darkColor};
    font-size: 1.4rem;
    font-weight: bold;
    font-style: italic;
    


    @media only screen and (max-width: 1324px) {
        font-size: 1rem;
  }
  @media only screen and (max-width: 820px) {
    font-size: 0.9rem;

    
  }
`;
export const GitHub = styled.a`
    position: absolute;
    top: 75%;
    left:5%;
    width: 90%;
    color: ${darkColor};
    font-size: 1.4rem;
    font-weight: bold;
    font-style: italic;
    


    @media only screen and (max-width: 1324px) {
        font-size: 1rem;
  }
  @media only screen and (max-width: 820px) {
    font-size: 0.9rem;
    
  }
`;
export const Explain = styled.p`

    position:absolute;
    top: 20%;
    left: 5%;
    width: 80%;
    font-size:1.4rem;
    line-height: 1.8;
    color:#6f6f6f;
    @media only screen and (max-width: 1324px) {
        font-size: 1rem;
  }
  @media only screen and (max-width: 820px) {
    font-size: 0.8rem;
    
  }
`;
export const Image1 = styled.img`
    position:absolute;
    top: 5%;
    right: 5%;
    height:6rem;
    width:6rem;
    @media only screen and (max-width: 1324px) {
        height:4.5rem;
        width:4.5rem;
        
  }
  @media only screen and (max-width: 820px) {
        height:3.5rem;
        width:3.5rem;
   
    
  }

`