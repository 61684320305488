import styled from "styled-components";
import { mainColor } from "../color";

export const ContainerDiv = styled.div`
&::-webkit-scrollbar {
  display: none;
}
    position:absolute;
    top: 80px;
    bottom: 0;
    left: 0;
    right: 0;
    height: 95%;
    width: 100%;
    margin: 0;
    padding: 0;
    z-index: 0;
    background: black;
    overflow-x: hidden;
    overflow-y: auto;
`;
export const Title = styled.h1`
    font-size: 1.6rem;  
    position:absolute;
    width:80%;
    left:10%;
    top:10%;
    text-align: center;
    margin:0 auto;
    color:${mainColor};
    @media only screen and (max-width: 820px) {
      font-size: 1.4rem;

  }
`;
export const LeftImg = styled.img`
  position: absolute;
  width:400px;
  height:auto;
  top:20%;
  left:5%;
 
  display: block;
  margin-left: auto;
  margin-right: auto;
  
  margin:0 auto;
`;
export const SearchA = styled.a`
    &:hover{
      color:${mainColor};
      font-size:1.5rem;
    }
    font-size: 1.4rem;  

    position:absolute;
    width:80%;
    left:10%;
    top:30%;
    text-align: center;
    margin:0 auto;
    color:#c9c9c9;
    @media only screen and (max-width: 820px) {
      font-size: 1.3rem;
      &:hover{
      font-size:1.4rem;
    }

  }
`;
export const ProjectsA = styled.a`
    &:hover{
          color:${mainColor};
          font-size:1.5rem;
        }
    position:absolute;
    font-size: 1.4rem;
    text-decoration: none; 

    width:80%;
    left:10%;
    top:45%;
    text-align: center;
    margin:0 auto;
    color:#c9c9c9;
    @media only screen and (max-width: 820px) {
      font-size: 1.3rem;
      &:hover{
      font-size:1.4rem;
    }

  }
`;
export const MrGA = styled.a`
    &:hover{
          color:${mainColor};
          font-size:1.5rem;
        }
    position:absolute;
    font-size: 1.4rem;  

    width:80%;
    left:10%;
    top:60%;
    text-align: center;
    margin:0 auto;
    color:#c9c9c9;
    @media only screen and (max-width: 820px) {
      font-size: 1.3rem;
      &:hover{
      font-size:1.4rem;
    }
  }
`;
export const LastDiv = styled.div`
position: relative;
top:100%;
width: 100%;
height: 5rem;
`;
export const AdminPanelDiv = styled.div`
    position:absolute;
    font-size: 1.4rem;
    width:30%;
    height:45rem;
    top:15%;
    left: 35%;
    text-align: center;
    font-weight:bold;
    margin:0 auto;
    color:${mainColor};
    background-color:black;
    border: 5px outset ${mainColor};
    font-family: 'Teko', sans-serif;
    @media only screen and (max-width: 1320px) {
      font-size: 1.3rem;
      height:35rem;
      width:55%;
      left:22.5%;
  }
    @media only screen and (max-width: 820px) {
      font-size: 1.3rem;
      height:30rem;
      width:70%;
      left:15%;
  }
`;
export const LogOutButton = styled.button`
    &:hover{
          background-color:white;
          color:black;
          font-size:1.5rem;
        }
    position:absolute;
    font-size: 1.4rem;
    width:35%;
    height:2.5rem;
    left:32.5%;
    bottom:10%;
    text-align: center;
    margin:0 auto;
    font-weight:bold;
    background-color:${mainColor};
    color:black;
  
    @media only screen and (max-width: 1320px) {
      font-size: 1.2rem;
      left:30%;
      height:2.2rem;
      width:40%;
      &:hover{
      font-size:1.3rem;
    }
  }
`;