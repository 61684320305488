import React from "react";
import { AboutMeDiv, Explain, Title } from "./AboutStyle";

export const AboutMe = ()=>{
    return(
        <AboutMeDiv>
            <Title>
                About Me...
            </Title>
            <Explain>
                Senior Computer Science student in Sabancı University, trying to improve himself on every major in software: Cyber Security,
                OPP, Web services, Mobile services etc. Having various projects, see them in the <a href="/projects">project</a> page.  
            </Explain>
        </AboutMeDiv>
    )
}