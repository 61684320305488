import React from "react";
import { ChatButton, InnerCard, NameTitle, UCard } from "./userCardStyle";


export const UserCard = (props) =>{

    const handleChat = ()=>{
        window.location.assign("/chat/"+props.source_username+"/"+props.destination_username)
    }
    return(
    <UCard>
        <InnerCard>
            <NameTitle>{props.destination_username}</NameTitle>
            <ChatButton onClick={handleChat}>Chat</ChatButton>
        </InnerCard>
    </UCard>
    )
}