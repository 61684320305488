import styled from "styled-components";
import { mainColor } from "../../color";

export const ContainerDiv = styled.div`
&::-webkit-scrollbar {
  display: none;
}
    position:absolute;
    top: 80px;
    bottom: 0;
    left: 0;
    right: 0;
    height: 95%;
    width: 100%;
    margin: 0;
    padding: 0;
    z-index: 0;
    background: black;
    overflow-x: hidden;
    overflow-y: auto;
`;
export const Title = styled.h1`
    font-size: 1.6rem;
    position:absolute;
    width:80%;
    left:10%;
    top:2%;
    text-align: center;
    margin:0 auto;
    color:${mainColor};
    @media only screen and (max-width: 820px) {
      font-size: 1.6rem;
  }
`;
export const SubmitButton = styled.button`
    position:absolute;
    font-size: 1.5rem;
    width:15%;
    height:2.5rem;
    left:42.5%;
    bottom:2%;
    text-align: center;
    margin:0 auto;
    font-weight:bold;
    background-color:${mainColor};
    color:black;
  
    @media only screen and (max-width: 1320px) {
      font-size: 1.2rem;
      left:30%;
      height:2.2rem;
      width:40%;
  }
`;

export const InputTitle = styled.input`
    position:absolute;
    font-size: 1.4rem;
    width:70%;
    top:15%;
    left: 15%;
    text-align: center;
    margin:0 auto;
    background-color:black;
    color:${mainColor};
    @media only screen and (max-width: 1320px) {
      font-size: 1.2rem;
      width:80%;
      left:10%;
 
  }
`;
export const InputSize = styled.input`
    position:absolute;
    font-size: 1.4rem;
    width:70%;
    top:25%;
    left: 15%;
    text-align: center;
    margin:0 auto;
    background-color:black;
    color:${mainColor};
    @media only screen and (max-width: 1320px) {
      font-size: 1.2rem;
      width:80%;
      left:10%;
 
  }
`;
export const InputType = styled.input`
    position:absolute;
    font-size: 1.4rem;
    width:70%;
    top:35%;
    left: 15%;
    text-align: center;
    margin:0 auto;
    background-color:black;
    color:${mainColor};
    @media only screen and (max-width: 1320px) {
      font-size: 1.2rem;
      width:80%;
      left:10%;
 
  }
`;
export const InputLanguages = styled.input`
    position:absolute;
    font-size: 1.4rem;
    width:70%;
    top:45%;
    left: 15%;
    text-align: center;
    margin:0 auto;
    background-color:black;
    color:${mainColor};
    @media only screen and (max-width: 1320px) {
      font-size: 1.2rem;
      width:80%;
      left:10%;
 
  }
`;
export const InputLink = styled.input`
    position:absolute;
    font-size: 1.4rem;
    width:70%;
    top:55%;
    left: 15%;
    text-align: center;
    margin:0 auto;
    background-color:black;
    color:${mainColor};
    @media only screen and (max-width: 1320px) {
      font-size: 1.2rem;
      width:80%;
      left:10%;
 
  }
`;
export const InputExplanation = styled.textarea`
  position: absolute;
  font-size: 1.4rem;
  top: 65%;
  left: 10%;
  margin: 0 auto;
  text-align: top;
  
  Row: 3;
  Column: 10;
  width: 80%;
  height:25%;
  resize:none;
  box-shadow: 5px 5px 5px  rgba(0, 0, 0, 0.15);

  border: black;
  border-size: 5px;
  @media only screen and (max-width: 1320px) {
      font-size: 1.2rem;
      width:80%;
      left:10%;
 
  }
  
`;




export const AddDiv = styled.div`
    position:absolute;
    font-size: 1.4rem;
    width:65%;
    height:45rem;
    top:5%;
    left: 17.5%;
    text-align: center;
    font-weight:bold;
    margin:0 auto;
    color:${mainColor};
    background-color:black;
    border: 5px outset ${mainColor};
    font-family: 'Teko', sans-serif;
    @media only screen and (max-width: 1320px) {
      font-size: 1.3rem;
      width:70%;
      left:15%;
  }
    @media only screen and (max-width: 820px) {
      font-size: 1.3rem;
      width:80%;
      left:10%;
  }
`;
export const LastDiv = styled.div`
position: relative;
top:100%;
width: 100%;
height: 5rem;
`;

