
import './App.css';
import { Routers } from './Routes';

function App() {
  return (
    <Routers/>
  
  );
}

export default App;
