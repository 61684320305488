
export const searchData = [
    {
        title: "Peak Games",
        link: "https://peak.com/"
    },
    {
        title: "Supercell",
        link: "https://supercell.com/en/"

    },
    {
        title: "Unity",
        link: "https://unity.com/"

    },
    {
        title: "Apple",
        link: "https://www.apple.com/tr/"

    },
    {
        title: "Spyke Games",
        link: "https://spykegames.com/"

    },
    {
        title: "Togg",
        link: "https://www.togg.com.tr/"

    },
    {
        title: "BMW",
        link: "https://www.bmw.com/en/index.html"

    },
    {
        title: "Brackeys",
        link: "https://www.youtube.com/@Brackeys"

    },
    {
        title: "How to develop game in unity?",
        link: "https://www.youtube.com/watch?v=IlKaB1etrik&ab_channel=Brackeys"

    },
    {
        title: "Riot Games",
        link: "https://www.riotgames.com/en"

    },
    {
        title: "Rockstar Games",
        link: "https://www.rockstargames.com/"

    },
    {
        title: "What is my dream car?",
        link: "https://www.google.com/search?q=ferrari+458+italia&tbm=isch&ved=2ahUKEwj6rfWCytv8AhV9x7sIHaxNDb4Q2-cCegQIABAA&oq=ferrari+458&gs_lcp=CgNpbWcQARgBMggIABCABBCxAzIICAAQgAQQsQMyCwgAEIAEELEDEIMBMgUIABCABDIFCAAQgAQyBQgAEIAEMgUIABCABDIFCAAQgAQyBQgAEIAEMgUIABCABDoHCAAQsQMQQzoKCAAQsQMQgwEQQ1AAWNMJYKASaABwAHgAgAGEAYgBgQSSAQMwLjSYAQCgAQGqAQtnd3Mtd2l6LWltZ8ABAQ&sclient=img&ei=uGHNY_rxLf2O7_UPrJu18As&bih=937&biw=1920#imgrc=cIUlO_vuRtvpiM"
    },
    {
        title: "Which work do I want to work on?",
        link: "https://www.google.com/search?q=game+developer&hl=tr&sxsrf=AJOqlzXEFT3L8umL10bxTHDZhcecEATw8g:1674404350783&source=lnms&tbm=isch&sa=X&ved=2ahUKEwj_qqekytv8AhVlVfEDHfG-C6cQ_AUoAXoECAEQAw&biw=1920&bih=937&dpr=1"

    },
    {
        title: "Ferrari",
        link: "https://www.ferrari.com/en-TR"

    },
    {
        title: "Lamborghini",
        link: "https://www.lamborghini.com/en-en"

    },
    
]