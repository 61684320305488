import React from "react";

import { DCard,ChatButton, InnerCard, NameTitle, DateTitle, MessageP } from "./destStyle";


export const DesCard = (props) =>{

    const formattedDay = new Date(props.day.seconds * 1000).toLocaleString();

    return(
    <DCard>
        <InnerCard>
            <NameTitle>{props.username}</NameTitle>
            <DateTitle>{formattedDay}</DateTitle>
            <MessageP> {props.message}</MessageP>
        </InnerCard>
    </DCard>
    )
}